import { Box, Breadcrumbs, Link } from '@mui/material';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import TagIcon from '@mui/icons-material/Tag';
// import { collection, getDocs } from "firebase/firestore";
// import { db } from '../../firebaseConfig';
const BlogHero = ({
    breadcrumbs = [],
    title = "Untitled Post",
    // teaser = "Untitled teaser",
    // date = "Unknown Date",
    // readTime = "N/A",
    // tag = "General",
    // author = { name: "Author", avatar: "" },
    imageUrl = "https://nuclblogpostimage.s3.eu-west-2.amazonaws.com/london-advertising-trafalgar-square.jpg/london-advertising-trafalgar-square.jpg"
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                    {breadcrumbs.map((breadcrumb=[], index) => (
                        <Link key={index} color="inherit" href={breadcrumb.href}>
                            {breadcrumb.label}
                        </Link>
                    ))}
                </Breadcrumbs>

            {/*    <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 1 }}>{title}</Typography>*/}

            {/*    <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>{teaser}</Typography>*/}

            {/*    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2, flexWrap: 'wrap' }}>*/}

            {/*        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>*/}
            {/*            <CalendarTodayIcon fontSize="small" sx={{ color: 'text.secondary' }} />*/}
            {/*            <Typography variant="body2">{date}</Typography>*/}
            {/*        </Box>*/}

            {/*        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>*/}
            {/*            <AccessTimeIcon fontSize="small" sx={{ color: 'text.secondary' }} />*/}
            {/*            <Typography variant="body2">{readTime} min read</Typography>*/}
            {/*        </Box>*/}

            {/*        <Chip icon={<TagIcon />} label={tag} size="small" variant="outlined" />*/}

            {/*        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>*/}
            {/*            <Avatar sx={{ width: 24, height: 24 }} src={author.avatar} alt={author.name} />*/}
            {/*            <Typography variant="body2">{author.name}</Typography>*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            </Box>

            {/* Right Column for Image */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <Box
                    component="img"
                    src={imageUrl}
                    alt={title}
                    sx={{
                        maxWidth: '100%',
                        borderRadius: 2,
                        boxShadow: 3,
                        height: { xs: 'auto', md: 250 },
                        width: { xs: '100%', md: 'auto' }
                    }}
                />
            </Box>
        </Box>
    );
};

export default BlogHero;
