import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import './Breadcrumbs.scss';

const BreadcrumbsComponent = () => {
    return (
        <div className="breadcrumb-container">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                <Link to="/" className="breadcrumb-link">
                    <Typography color="textPrimary" className="breadcrumb-item">
                        <HomeIcon fontSize="small" className="breadcrumb-icon" />
                        Home
                    </Typography>
                </Link>
                <Link to="/blog" className="breadcrumb-link">
                    <Typography color="textPrimary" className="breadcrumb-item">
                        Blog
                    </Typography>
                </Link>
                <Typography color="textSecondary" className="breadcrumb-item">
                    Post Title
                </Typography>
            </Breadcrumbs>
        </div>
    );
};

export default BreadcrumbsComponent;
