import React, { useEffect } from 'react';
import {
	BarChart,
	PieChart,
	Bar,
	Pie,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	Cell,
	CartesianGrid,
} from 'recharts';
import styles from './Showcase.module.scss';
import Layout from "../../layout/layout";

const projects = [
	{
		title: 'Lexi - Renovations professionals in London',
		description: 'View live web application',
		url: 'https://lexi.ltd',
		status: 'In maintenance'
	},
	{
		title: 'Hot Windscreens - Windscreen repair professionals in London',
		description: 'View live web application',
		url: 'https://windscreensltd.co.uk/',
		status: 'In maintenance'
	},
	{
		title: 'Super Clean Life - Professional cleaning solutions in London',
		description: 'View live web application',
		url: 'https://supercleanlife.vercel.app/',
		status: 'Work in progress'
	},
];

const insightsData1 = [
	{ name: 'e-Commerce', value: 400 },
	{ name: 'Brochure Website', value: 300 },
	{ name: 'Promo Website', value: 300 },
];

const insightsData2 = [
	{ name: 'Month 1', uv: 4000, pv: 2400 },
	{ name: 'Month 2', uv: 3000, pv: 1398 },
	{ name: 'Month 3', uv: 2000, pv: 9800 },
];

// const highlights = [
//     'Highlight 1',
//     'Highlight 2',
//     'Highlight 3',
// ];

const Showcase = () => {

		useEffect(() => {

			const jsonLd = {
				"@context": "https://schema.org",
				"@type": "WebPage",
				"name": "Showcase | nuclecode - Innovative Web Development Solutions",
				"description": "Explore nuclecode&apos;s latest web development projects showcasing our expertise in creating high-performance, accessible, and user-friendly digital experiences.",
				"url": "https://nuclecode.uk/showcase",
				"mainEntity": [
					{
						"@type": "CreativeWork",
						"name": "Lexi - Renovations professionals in London",
						"url": "https://lexi.ltd",
						"description": "Click to see this project live",
						"creator": {
							"@type": "Organization",
							"name": "nuclecode"
						}
					},
					{
						"@type": "CreativeWork",
						"name": "My Ecom - The online store of many things",
						"description": "Project will be live soon!",
						"creator": {
							"@type": "Organization",
							"name": "nuclecode"
						}
					}
				],
				"about": {
					"@type": "Organization",
					"name": "nuclecode",
					"url": "https://nuclecode.uk",
					"logo": "https://nuclecode.uk/logo-website.svg",
					"sameAs": [
						"https://www.linkedin.com/company/nuclecode/",
						"https://twitter.com/nuclecode",
						"https://www.facebook.com/nuclecode",
						"https://www.pinterest.com/nuclecode"
					],
					"contactPoint": {
						"@type": "ContactPoint",
						"telephone": "+44-735-557-3823",
						"contactType": "Customer Service",
						"areaServed": ["GB", "US", "DE", "EU"],
						"availableLanguage": ["English", "Russian"]
					}
				},
				"potentialAction": {
					"@type": "SearchAction",
					"target": "https://nuclecode.uk/?s={search_term_string}",
					"query-input": "required name=search_term_string"
				}
			};

			const script = document.createElement('script');
			script.type = 'application/ld+json';
			script.innerHTML = JSON.stringify(jsonLd);
			document.head.appendChild(script);

			return () => {
				document.head.removeChild(script);
			};
		}, []);

		return (
			<Layout title="Showcase | nuclecode - Innovative Web Development Solutions" description="Explore nuclecode&apos;s latest web development projects showcasing our expertise in creating high-performance, accessible, and user-friendly digital experiences.">
				<div className={styles.showcase}>
					<h1>Our most recent projects</h1>
					<section className={styles.customerProjects}>
						<h2>Customer Projects</h2>
						<div className={styles.projectList}>
							{projects.map((project, index) => (
								<div key={index} className={styles.project} onClick={() => window.open(project.url, '_blank')}>
									<h3 className={styles.projectTitle}>{project.title}</h3>
									<p>{project.description}</p>
									<div className={styles.status}>
										<span
											className={`${styles.statusCircle} ${
											project.status === 'Work in progress'
											? styles.wip
											: project.status === 'In maintenance'
											? styles.maintenance
											: styles.other
											}

										`}></span>
										<span>{project.status}</span>
									</div>
								</div>
							))}
						</div>
					</section>
					<section className={styles.insightsBar}>
						<h2>Insights Bar</h2>
						<div className={styles.insightsContainer}>
							{/* Example charts */}
							<div className={styles.insightSection}>
								<h3>Client Distribution</h3>
								<PieChart width={300} height={300}>
									<Pie data={insightsData1} cx={150} cy={150} outerRadius={80} fill="#8884d8" dataKey="value">
										{insightsData1.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
										))}
									</Pie>
									<Tooltip />
								</PieChart>
							</div>
							<div className={styles.insightSection}>
								<h3>Monthly Performance</h3>
								<BarChart width={300} height={300} data={insightsData2}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Legend />
									<Bar dataKey="pv" fill="#82ca9d" />
								</BarChart>
							</div>
						</div>
					</section>
					{/* Uncomment this section if you want to add highlights carousel back */}
					{/* <section className={styles.highlightsCarousel}> */}
					{/*     <h2>Highlights Carousel</h2> */}
					{/*     <div className={styles.embla} ref={emblaRef}> */}
					{/*         <div className={styles.embla__container}> */}
					{/*             {highlights.map((highlight, index) => ( */}
					{/*                 <div className={styles.embla__slide} key={index}> */}
					{/*                     <img */}
					{/*                         className={styles.embla__slide__img} */}
					{/*                         src={`https://via.placeholder.com/300x200?text=${highlight}`} */}
					{/*                         alt={highlight} */}
					{/*                         loading="lazy" */}
					{/*                     /> */}
					{/*                     <p>{highlight}</p> */}
					{/*                 </div> */}
					{/*             ))} */}
					{/*         </div> */}
					{/*     </div> */}
					{/* </section> */}
				</div>
			</Layout>
		);
	};

	export default Showcase;
