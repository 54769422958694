import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import styles from './BlogPostDetails.module.scss';
import Breadcrumbs from './Breadcrumbs';
import BlogHero from "./BlogHero";

const BlogPostDetails = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const q = query(collection(db, 'blog'), where('slug', '==', slug));
                const postSnapshot = await getDocs(q);
                if (!postSnapshot.empty) {
                    setPost(postSnapshot.docs[0].data());
                } else {
                    console.log("No post found with that slug.");
                }
            } catch (error) {
                console.error("Error fetching post:", error);
            } finally {
                setLoading(false);
            }
        };

        if (slug) {
            fetchPost();
        } else {
            console.log("Slug is undefined or empty.");
            setLoading(false);
        }
    }, [slug]);

    if (loading) return <p>Loading...</p>;

    return (
        <div className={styles.detailContainer}>
            <Breadcrumbs />
            <BlogHero />
            {post ? (
                <>
                    <h1 className={styles.title}>{post.title}</h1>
                    {post.imageUrl && (
                        <div
                            className={styles.imageContainer}
                            style={{ backgroundImage: `url(${post.imageUrl})` }}
                        />
                    )}
                    <div className={styles.content}>{post.content}</div>
                    <Link to="/blog" className={styles.backLink}>Back to All Posts</Link>
                </>
            ) : (
                <p>Post Not Found</p>
            )}
        </div>
    );
};

export default BlogPostDetails;
