import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import styles from './NewBlog.module.scss';
import Layout from '../../layout/layout';
const BlogList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const categories = ["Construction", "SEO", "Web Design", "Software Testing", "Social Media posts", "E-commerce", "Digital Ads", "Web Development"];

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const q = query(collection(db, 'blog'), orderBy('createdAt', 'desc'));
                const snapshot = await getDocs(q);
                if (snapshot.empty) {
                    setError("No posts found.");
                    return;
                }
                const postsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPosts(postsData);
            } catch (err) {
                setError("Error fetching posts.");
            } finally {
                setLoading(false);
            }
        };
        fetchPosts();
    }, []);

    if (loading) return <p>Loading...</p>;

    return (
        <Layout>
        <div className={styles.blogContainer}>
            <div className={styles.banner}>
                <h1>News</h1>
                <p>Discover the latest trends, innovations, and insights in the industry! Explore our comprehensive News section today!</p>
            </div>

            <div className={styles.categories}>
                {categories.map((category, index) => (
                    <button key={index} className={styles.categoryButton}>{category}</button>
                ))}
            </div>

            {error && <p className={styles.error}>{error}</p>}

            <div className={styles.postGrid}>
                {posts.map(post => (
                    <div key={post.id} className={styles.blogPost} style={{ backgroundImage: `url(${post.imageUrl})` }}>
                        <Link to={`/posts/${post.slug}`} className={styles.postLink}>
                            <div className={styles.postContent}>
                                <h2 className={styles.postTitle}>{post.title}</h2>
                                <p className={styles.postExcerpt}>{post.content.substring(0, 100)}...</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    </Layout>
    );
};

export default BlogList;
