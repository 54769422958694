import React from 'react';
import BlogPostDetails from '../../components/NewBlog/BlogPostDetails';
import Layout from '../../layout/layout';
const BlogPostPage = () => {
    return (
        <Layout>
        <div>
            <BlogPostDetails />
        </div>
        </Layout>
    );
};

export default BlogPostPage;
