import React from 'react';
import Layout from '../../layout/layout';
import { Typography, Button, Grid } from '@mui/material';
import Card from '../../components/card/card';
import './careers.scss';

function Careers() {
    const reasonsToWorkWithUs = [
        {
            image: "/pexels-olly-3777570.jpg",
            text: "We’re committed to creating a dynamic environment where your ideas are valued and you have the freedom to innovate."
        },
        {
            image: "/pexels-mikhail-nilov-7731345.jpg",
            text: "Join us to work with cutting-edge technology and continually learn and grow."
        },
        {
            image: "/pexels-tima-miroshnichenko-5717324.jpg",
            text: "Benefit from flexible work hours, remote options, and a collaborative team."
        }
    ];

    return (
        <Layout
            title="Careers | nuclecode - Join Our Team of Experts"
            description="Explore career opportunities at nuclecode. Join a team dedicated to innovative web development and start your journey with us."
        >
            <div className="careers-page-container page-top-space">
                <div className="container relative">
                    <Typography variant="h1">Careers at nuclecode</Typography>

                    <section className="intro-section">
                        <Typography variant="subtitle1" className="subtitle">
                            Join our team of skilled professionals and become part of a company that values innovation,
                            growth, and dedication to excellence.
                        </Typography>
                    </section>

                    <section className="open-positions-section">
                        <Typography variant="h2">Open Positions</Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12} md={6}>
                                <Card className="job-card">
                                    <Typography variant="h4">Frontend Developer</Typography>
                                    <Typography variant="body1">
                                        We are looking for a skilled Frontend Developer with experience in React and
                                        MUI. Join us to create stunning, responsive interfaces for our clients.
                                    </Typography>
                                    <Button variant="contained" color="primary" className="apply-button">
                                        Apply Now
                                    </Button>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card className="job-card">
                                    <Typography variant="h4">Backend Developer</Typography>
                                    <Typography variant="body1">
                                        Seeking an experienced Backend Developer with expertise in Node.js and
                                        PostgreSQL to work on scalable backend solutions for our projects.
                                    </Typography>
                                    <Button variant="contained" color="primary" className="apply-button">
                                        Apply Now
                                    </Button>
                                </Card>
                            </Grid>
                        </Grid>
                    </section>

                    <section className="carousel-section">
                        <h2>Why Work with Us?</h2>
                        <div className="carousel">
                            {reasonsToWorkWithUs.map((item, index) => (
                                <div className="custom-card" key={index}>
                                    <div className="custom-card-media" style={{backgroundImage: `url(${item.image})`}}/>
                                    <div className="custom-card-content">
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="testimonials-section">
                        <Typography variant="h2">Employee Testimonials</Typography>

                        <Card className="testimonial-card">
                            <Typography variant="body1" className="testimonial">
                                "Working at nuclecode has been an incredible journey. The team is supportive, and every
                                project brings new learning opportunities!"
                            </Typography>
                            <Typography variant="subtitle2">- Lucy Manor, Senior Product Manager</Typography>
                        </Card>
                    </section>

                    <section className="application-process-section">
                        <Typography variant="h2">Our Application Process</Typography>
                        <div className="process-steps">
                            <div className="step">
                                <Typography variant="h6">1. Submit Application</Typography>
                                <Typography variant="body2">
                                    Send your resume and cover letter through our online application portal.
                                </Typography>
                            </div>
                            <div className="step">
                                <Typography variant="h6">2. Initial Interview</Typography>
                                <Typography variant="body2">
                                    Meet with a recruiter to discuss your experience and aspirations.
                                </Typography>
                            </div>
                            <div className="step">
                                <Typography variant="h6">3. Technical Assessment</Typography>
                                <Typography variant="body2">
                                    Complete a technical assessment to showcase your skills.
                                </Typography>
                            </div>
                            <div className="step">
                                <Typography variant="h6">4. Final Interview</Typography>
                                <Typography variant="body2">
                                    Have a final discussion with our team and learn more about nuclecode.
                                </Typography>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
}

export default Careers;
