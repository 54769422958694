import React from 'react';
import CookieConsent from 'react-cookie-consent';

const App = () => {
    return (
        <div>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="myAwesomeCookieName"
                style={{ background: "#939eff" }}
                buttonStyle={{ color: "#00022B", fontSize: "16px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
                <span style={{ fontSize: "10px" }}> You can opt-out.</span>
            </CookieConsent>
        </div>
    );
};

export default App;
