import React from 'react';
import Layout from '../../../layout/layout';
import { Typography } from '@mui/material';

import './backend.scss';

function Backend() {
	return (
		<Layout title="Back-End Development | nuclecode - Scalable and Secure Systems." description="Power your applications with nuclecode&apos;s robust back-end development solutions, ensuring scalability, security, and seamless performance for your business.">
			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "https://schema.org",
					"@type": "WebPage",
					"name": "Back-End Development | nuclecode",
					"url": "https://nuclecode.uk/solutions/backend",
					"description": "Power your applications with nuclecode&apos;s robust back-end development solutions, ensuring scalability, security, and seamless performance for your business.",
					"mainEntity": {
						"@type": "Service",
						"serviceType": "Back-End Development Services",
						"provider": {
							"@type": "Organization",
							"name": "nuclecode",
							"url": "https://nuclecode.uk",
							"logo": "https://nuclecode.uk/logo-website.svg",
							"sameAs": [
								"https://twitter.com/nuclecode",
								"https://www.linkedin.com/company/nuclecode/"
							]
						},
						"areaServed": ["GB", "US", "DE", "EU"],
						"availableChannel": {
							"@type": "ServiceChannel",
							"serviceLocation": {
								"@type": "Place",
								"address": {
									"@type": "PostalAddress",
									"addressCountry": "GB"
								}
							}
						},
						"offers": {
							"@type": "Offer",
							"url": "https://nuclecode.uk/solutions/backend",
							"priceCurrency": "GBP",
							"eligibleRegion": {
								"@type": "Place",
								"name": "United Kingdom"
							}
						}
					}
				})}
			</script>
			<div className="backend-solution-page-container page-top-space">
				<div className="container">
					<Typography variant="h1">Backend Development</Typography>

					<page-section>
						<Typography variant="body1">
							Behind every great website is a powerful backend
							infrastructure that drives performance and functionality.
							Our backend development experts are masters at architecting
							scalable, efficient systems that handle complex operations with
							ease. From database design to server-side scripting, we ensure
							that your website runs smoothly and securely, no matter the demands.
						</Typography>
					</page-section>
				</div>
			</div>
		</Layout>
	);
}

export default Backend;
