import React from 'react';
import BlogList from '../../components/NewBlog/NewBlog';

const BlogPage = () => {
    return (
        <div>
            <BlogList />
        </div>
    );
};

export default BlogPage;
