import React, { useEffect } from 'react';
import styles from './Demos.module.scss';
import { FiExternalLink } from 'react-icons/fi';
import Layout from '../../layout/layout';

const demoWebsites = [
    { name: "e-Commerce Store", url: "https://idncod-store.netlify.app/products" },
    // { name: "Website 2", url: "https://example2.com" },
];

const Demos = () => {

	useEffect(() => {
		const jsonLd = {
			"@context": "https://schema.org",
			"@type": "WebPage",
			"name": "Demos | nuclecode - See Our Demo Work in Action",
			"description": "Explore the demo websites created by nuclecode to experience the speed, design, and functionality of our digital solutions firsthand.",
			"url": "https://nuclecode.com/demos", 
			"mainEntity": {
				"@type": "ItemList",
				"itemListElement": demoWebsites.map((demo, index) => ({
					"@type": "ListItem",
					"position": index + 1,
					"name": demo.name,
					"url": demo.url
				}))
			},
			"potentialAction": {
				"@type": "SearchAction",
				"target": "https://nuclecode.uk/demos?s={search_term_string}",
				"query-input": "required name=search_term_string"
			},
			"breadcrumb": {
				"@type": "BreadcrumbList",
				"itemListElement": [
					{
						"@type": "ListItem",
						"position": 1,
						"name": "Home",
						"item": "https://nuclecode.uk"
					},
					{
						"@type": "ListItem",
						"position": 2,
						"name": "Demos",
						"item": "https://nuclecode.uk/demos"
					}
				]
			}
		};

		const script = document.createElement("script");
		script.type = "application/ld+json";
		script.innerHTML = JSON.stringify(jsonLd);
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);

    const openWebsiteInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
		<Layout title="Demos | nuclecode - See Our Demo Work in Action." description="Explore the demo websites we have created to see how our projects look and perform. Browse live demos of nuclecode&apos;s websites and applications to experience the speed, design, and functionality of our digital solutions firsthand.">
            <div className={styles.demos}>
                <h1>See how the websites we make look and perform.</h1>
                <div className={styles.demosGrid}>
                    {demoWebsites.map((demo, index) => (
                        <div key={index} className={styles.demoCard}>
                            <h2 className={styles.demoTitle}>{demo.name}</h2>
                            <iframe
                                src={demo.url}
                                title={demo.name}
                                loading="lazy"
                                className={styles.demoIframe}
                                allowFullScreen
                            ></iframe>
                            <FiExternalLink
                                className={styles.externalLinkIcon}
                                onClick={() => openWebsiteInNewTab(demo.url)}
                                title={`Open ${demo.name}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default Demos;
